import React from 'react'

import {
  ActionButton,
  List,
  ListItem,
  Paragraph,
  Strong
} from '@te-digi/styleguide'

import { ActionButtonHTMLExample } from '../../examples/html/ActionButtonHTMLExample'
import { ActionButtonExample } from '../../examples/react/ActionButtonExample'
import { Code } from '../../components/Code'
import { Content } from '../../components/Content'
import { Playground } from '../../components/Playground'
import { LinkMock } from '../../components/LinkMock'
import { Section } from '../../components/Section'

// HUOM! lead-teksti määritellään pages.ts -tiedostossa
const Page = () => (
  <Content
    heading="ActionButton"
    components={[{ component: ActionButton, restElement: true }]}
    status={[{ type: 'accessible', version: '14.4.1' }]}
  >
    <Section>
      <Playground example={ActionButtonExample} />
    </Section>
    <Section>
      <Paragraph noMargin>Käytetään esimerkiksi:</Paragraph>
      <List variant="unordered">
        <ListItem>
          Ohjaamaan käyttäjä näkymään, joka tarjoaa asiaan oleellisesti
          liittyviä lisätietoja tai -toimintoja
        </ListItem>
        <ListItem>
          Ohjaamaan käyttäjä etenemään seuraavaan mahdolliseen toimintoon
          prosessissa.
        </ListItem>
        <ListItem>
          Listaamaan käyttäjälle useita vaihtoehtoisia etenemistapoja.
        </ListItem>
      </List>
      <Paragraph>
        <Strong>Ei käytetä</Strong> lomakkeilla.
      </Paragraph>
      <Paragraph>
        <Code>ActionButton</Code>in tekstissä kuvataan lyhyesti ja selkeästi
        yhdellä lauseella informaatio tai toiminto, johon linkki ohjaa. Teksti
        aloitetaan kehoittavalla verbillä, kuten luo, tarkastele tai selaa.
      </Paragraph>
      <Paragraph>
        Jos yksi lyhyt lause ei riitä kuvaamaan informaatiota tai toimintoa
        tarpeeksi selkeästi, anna tarkentavaa lisätietoa kuvaustekstissä. Myös
        kuvausteksti pyritään pitämään mahdollisimman lyhyenä.
      </Paragraph>
    </Section>
    <Section title="Esimerkkejä">
      <Playground>
        <>
          <ActionButton
            as={LinkMock}
            description="Lorem ipsum dolor sit amet consectetur adipisicing elit"
          >
            Luo tai päivitä osaamisprofiilisi
          </ActionButton>
          <ActionButton as={LinkMock}>
            Selaa sinulle sopivia työpaikkoja
          </ActionButton>
          <ActionButton as={LinkMock}>Tarkastele omia tietojasi</ActionButton>
        </>
      </Playground>
      <Playground format="html">
        <>
          <ActionButtonHTMLExample description="Lorem ipsum dolor sit amet consectetur adipisicing elit">
            Luo tai päivitä osaamisprofiilisi
          </ActionButtonHTMLExample>
          <ActionButtonHTMLExample>
            Selaa sinulle sopivia työpaikkoja
          </ActionButtonHTMLExample>
          <ActionButtonHTMLExample>
            Tarkastele omia tietojasi
          </ActionButtonHTMLExample>
        </>
      </Playground>
    </Section>

    <Section title="Saavutettavuus">
      <Paragraph>
        Vaikka komponentin nimi on <Code>ActionButton</Code>, tyypillisin käyttö
        on välittää <Code>Link</Code>-komponentti <Code>as</Code>-propille.
        Tällöin <Code>ActionButton</Code> käyttäytyy semanttisesti kuten linkki
        (esim. ruudunlukija ilmoittaa että kyseessä on linkki). Periaate on,
        että linkki vie toiselle sivulle, painike tekee toiminnon.
      </Paragraph>
      <Paragraph>
        Voidaan ajatella, että <Code>ActionButton</Code>illa toteutetut linkit
        ovat erityisen tärkeitä linkkejä, joita on haluttu korostaa
        visuaalisesti. Sama korostus ei välity ruudunlukijalle.
      </Paragraph>
      <Paragraph>
        Ruudunlukijalle luetaan linkkitekstinä koko <Code>ActionButton</Code>in
        sisältö. Tästä syystä <Code>description</Code>-propin teksti kannattaa
        pitää lyhyenä, sillä se luetaan myös osana linkkitekstiä. Varmista, että
        painikkeen tekstistä käy ilmi, mihin käyttäjä siirtyy.
      </Paragraph>
    </Section>
  </Content>
)

export default Page

import React from 'react'
import { ActionButton, ExternalLinkIcon, Link } from '@te-digi/styleguide'

const ActionButtonExample = () => (
  <>
    <ActionButton
      href={'#'}
      as={Link}
      description="as Link component"
    >
      Link to this page
    </ActionButton>
    <ActionButton
      as="a"
      href={'http://www.google.com'}
      iconRight={<ExternalLinkIcon />}
      description="as native html &lt;a&gt; element"
      target="_blank"
    >
      Link to Google
    </ActionButton>
  </>
)

export { ActionButtonExample }

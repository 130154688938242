import React from 'react'

import { LinkMock } from '../../components/LinkMock'

import IconHTMLExample from './IconHTMLExample'

const ActionButtonHTMLExample = ({
  children,
  className,
  description
}: {
  children?: string
  className?: string
  description?: string
} = {}) => (
  <LinkMock className={['action-button', className].filter(Boolean).join(' ')}>
    <span className="action-button-label">
      <span className="action-button-label-heading">{children}</span>
      {description && (
        <span className="action-button-label-description">{description}</span>
      )}
    </span>
    <IconHTMLExample
      className="action-button-icon-right"
      name="ArrowRightIcon"
      size="xl"
    />
  </LinkMock>
)

export { ActionButtonHTMLExample }
